import { Helmet } from 'react-helmet-async'
import { Button, Card, Flex, Select } from '@radix-ui/themes'
import { RefreshCw } from 'lucide-react'
import { Content } from '~/views/layout/Content.tsx'
import { useProjectStore } from '~/stores/project.ts'
import { ProjectLogTable } from '~/views/project/ProjectLogTable.tsx'
import { useLog } from '~/views/project/useLog'

export function ProjectLog() {
  const projectStore = useProjectStore()
  const {
    logs,
    isLoading,
    error,
    limit,
    limits,
    hasMore,
    hasPrevious,
    nextPage,
    prevPage,
    changeLimit,
    fetchPage,
  } = useLog({ appId: projectStore.currentProject?.id, initialLimit: '50' })

  return (
    <Content title="Project Log">
      <Helmet>
        <title>
          Log /
          {' '}
          {projectStore.currentProject.name}
        </title>
      </Helmet>

      <Flex direction="column" gap="2" className="h-0 flex-1">
        <div className="flex justify-end">
          <Button
            size="1"
            variant="outline"
            onClick={() => {
              fetchPage()
            }}
          >
            <RefreshCw className="size-4" />
          </Button>
        </div>
        <Card className="relative flex-1">
          <div className="absolute inset-0">
            <ProjectLogTable error={error} data={logs} loading={isLoading} />
          </div>
        </Card>
        <div className="flex justify-end gap-2">
          <Select.Root
            value={limit}
            onValueChange={(value) => {
              changeLimit(value)
            }}
          >
            <Select.Trigger />
            <Select.Content>
              {limits.map(item => (
                <Select.Item key={item} value={`${item}`}>{item}</Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
          <Button onClick={prevPage} disabled={isLoading || !hasPrevious}>
            Previous Page
          </Button>
          <Button onClick={nextPage} disabled={isLoading || !hasMore}>
            Next Page
          </Button>
        </div>
      </Flex>
    </Content>
  )
}
