import { Badge, Button, Card, IconButton, Skeleton, Text, TextField } from '@radix-ui/themes'
import { useNavigate } from 'react-router-dom'
import { DotsHorizontalIcon, GitHubLogoIcon } from '@radix-ui/react-icons'
import { formatDistanceToNow, parseJSON } from 'date-fns'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import type { ProjectItem } from '~/stores/project'
import { setCurrentProject, useProjects } from '~/stores/project'
import { useTeamStore } from '~/stores/team'
import { Content } from '~/views/layout/Content.tsx'

export function TeamOverview() {
  const teamStore = useTeamStore()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { projects, error, isLoading } = useProjects(teamStore.currentTeam.id)

  function goProject(project: ProjectItem) {
    setCurrentProject(project)
    navigate(`/${teamStore.currentTeam.slug}/${project.name}`)
  }

  function goNewProject() {
    navigate(`/${teamStore.currentTeam.slug}/~/new`)
  }

  const renderProjects = () => {
    if (error)
      return <div className="text-center">Fail to load projects</div>
    if (isLoading) {
      return (
        <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
          <Card>
            <div className="flex items-center">
              <Skeleton><Text size="3" className="">Project Name</Text></Skeleton>
            </div>
            <div className="my-2 flex">
              <Skeleton>
                <Badge>
                  <GitHubLogoIcon />
                  {teamStore.currentTeam.name.toLowerCase()}
                  /
                  Project Name
                </Badge>
              </Skeleton>
            </div>
          </Card>
        </div>
      )
    }
    const allProjects = projects || []
    if (allProjects.length === 0) {
      return (
        <div className="text-center">
          <div className="text-lg font-medium">No projects found</div>
          <div className="text-zinc-500">Create a new project to get started</div>
        </div>
      )
    }
    const filterProjects = projects.filter((p) => {
      if (search === '')
        return true

      return p.name.includes(search)
    })
    if (filterProjects) {
      <div className="text-center">
        <div className="text-lg font-medium">No projects found</div>
      </div>
    }
    const results = filterProjects.map(project => (
      <div key={project.id} className="cursor-pointer" onClick={() => goProject(project)}>
        <Card>
          <div className="flex items-center">
            <Text size="3" className="">{project.name}</Text>
            <div className="flex flex-1 justify-end">
              <IconButton variant="ghost" onClick={(e) => { e.stopPropagation() }}>
                <DotsHorizontalIcon />
              </IconButton>
            </div>
          </div>
          <div className="my-2 flex">
            <Badge>
              <GitHubLogoIcon />
              {teamStore.currentTeam.name.toLowerCase()}
              /
              {project.name}
            </Badge>
          </div>
          <Text size="1" className="opacity-45">
            {`${formatDistanceToNow(parseJSON(project.createdAt))} ago`}
          </Text>
        </Card>
      </div>
    ))
    return (
      <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
        {results}
      </div>
    )
  }

  return (
    <Content title="Projects">
      <Helmet>
        <title>
          Projects
        </title>
      </Helmet>

      <div className="mb-6 flex gap-2">
        <TextField.Root size="3" className="flex-1" placeholder="Search Projects…" onChange={e => setSearch(e.target.value)} />
        <Button size="3" onClick={goNewProject}>
          New Project
        </Button>
      </div>
      {renderProjects()}
    </Content>
  )
}
