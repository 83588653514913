import { DeepChat } from 'deep-chat-react'
import type { DeepChat as DeepChatCore } from 'deep-chat'
import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react'
import { History } from 'lucide-react'
import { HoverCard } from '@radix-ui/themes'
import { toast } from 'sonner'
import { useProjectStore } from '~/stores/project'

const ChatUI = memo(forwardRef(({ token, projectId, tempPrompt }: { token: string, projectId: string, tempPrompt: string }, ref) => {
  const projectStore = useProjectStore()
  const [systemPrompt] = useState(projectStore.currentProject.aiPrompt)
  function handleUserMessage(message: string) {
    const messages = JSON.parse(localStorage.getItem(`AI_CHAT_HISTORY_${projectId}`) || '[]')
    if (messages.includes(message))
      return

    if (messages.length > 10)
      messages.shift()

    localStorage.setItem(`AI_CHAT_HISTORY_${projectId}`, JSON.stringify([...messages, message]))
  }

  return (
    <DeepChat
      ref={ref as any}
      className="z-0 !h-full max-h-[650px] w-full !flex-1 !border-none"
      connect={{
        url: `${import.meta.env.VITE_AI_BRIDGE_BASE_URL || 'https://dashboardapi.vivgrid.com/v1'}/chat/completions`,
      }}
      requestInterceptor={(request) => {
        if (!tempPrompt || !tempPrompt.trim())
          return request
        const promptOperation = localStorage.getItem(`PROMPT_OPERATION_${projectId}`) || '0'
        request.body.operation = Number(promptOperation)
        const sysMsg = request.body.messages.find((item: { role: string, content: string }) => item.role === 'system')
        switch (request.body.operation) {
          // Overwrite
          // 开启后，将覆盖 client 请求中的 system prompt
          case 0:
            request.headers!['X-Prompt-Evaluation'] = '0'
            sysMsg.content = tempPrompt
            break
          // Prefix
          // 开启后，将文本框内的内容 prefix 到 client 请求的 system prompt 之前，并在两者之间加入一个 \n
          case 2:
            request.headers!['X-Prompt-Evaluation'] = '2'
            sysMsg.content = `${tempPrompt}\n${systemPrompt}`
            break
          // Disabled
          // 停用该功能，此时将直接使用 client 请求
          case 1:
          default:
            // sysMsg.content = systemPrompt
            delete request.headers!['X-Prompt-Evaluation']
            break
        }
        return request
      }}
      onMessage={({ message }) => {
        if (message.role === 'user')
          handleUserMessage(message.text || '')
      }}
      errorMessages={{ displayServiceErrorMessages: true }}
      onError={(e) => {
        toast.error(e)
      }}
      directConnection={{
        openAI: {
          key: token,
          chat: {
            // max_tokens: 2000,
            // system_prompt: 'Assist me with anything you can',
          },
        },
      }}
      style={{
        width: '360px',
        backgroundColor: 'var(--gray-a3)',
      }}
      inputAreaStyle={{
        // backgroundColor: 'rgba(0, 32, 0, 0.063)',
      }}
      textInput={{
        styles: {
          container: {
            width: '308px',
            marginLeft: '20px',
            backgroundColor: 'var(--gray-2)',
            boxShadow: 'var(--gray-3) 0 1px 12px',
            color: 'var(--gray-12)',
          },
        },
        placeholder: {
          text: 'Prompt Evaluation',
        },
      }}
      messageStyles={{
        default: {
          user: {
            bubble: {
              backgroundColor: 'var(--jade-9)',
            },
          },
          ai: {
            bubble: {
              backgroundColor: 'var(--gray-3)',
              color: 'var(--gray-12)',
              maxWidth: '90%',
            },
          },
        },
      }}
      submitButtonStyles={{
        submit: {
          container: {
            default: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
          svg: {
            content: '<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 2L2 8.66667L11.5833 12.4167M22 2L15.3333 22L11.5833 12.4167M22 2L11.5833 12.4167" stroke="var(--jade-9)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          },
        },
      }}
    />
  )
}))

export const AiChat = forwardRef(({ token, projectId, tempPrompt }: { token: string, projectId: string, tempPrompt: string }, ref) => {
  const chatRef = useRef<any>(null)
  const [userHistoryMessages, setUserHistoryMessages] = useState<string[]>([])

  const clearMessages = () => {
    chatRef.current?.clearMessages()
  }

  useImperativeHandle(ref, () => {
    return {
      clearMessages,
    }
  })

  function handleSelectHistoryMessage(message: string) {
    const chat = chatRef.current as DeepChatCore

    chat.submitUserMessage({
      text: message,
    })
  }

  return (
    <div className="relative ml-4 flex w-[360px] flex-col">
      <HoverCard.Root onOpenChange={(open) => {
        if (open) {
          const messages = JSON.parse(localStorage.getItem(`AI_CHAT_HISTORY_${projectId}`) || '[]')
          setUserHistoryMessages(messages)
        }
      }}
      >
        <HoverCard.Trigger>
          <div className="absolute bottom-4 left-2 z-10 cursor-pointer">
            <History size={20} className="text-zinc-400" strokeWidth={1.5} />
          </div>
        </HoverCard.Trigger>
        <HoverCard.Content side="top" size="1" width="250px">
          {userHistoryMessages.map((item, index) => (
            <div key={index} title={item} className="cursor-pointer  truncate border-b border-zinc-100 p-2" onClick={() => handleSelectHistoryMessage(item)}>
              {item}
            </div>
          ))}
          {userHistoryMessages.length === 0 && <div className="cursor-pointer text-zinc-400">No history</div>}
        </HoverCard.Content>
      </HoverCard.Root>
      <ChatUI token={token} projectId={projectId} ref={chatRef} tempPrompt={tempPrompt} />
    </div>
  )
})
