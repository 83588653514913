import { useEffect, useState } from 'react'
import { Text } from '@radix-ui/themes'
import type { ProjectLog } from './useLog.ts'
import { useTeamStore } from '~/stores/team.ts'

export function ProjectLogStatusCode({ logRows }: { logRows?: ProjectLog[] }) {
  const teamStore = useTeamStore()
  const [teamPlanInfo, setTeamPlanInfo] = useState<any>()

  useEffect(() => {
    setTeamPlanInfo(teamStore.allTeamsPlan[teamStore.currentTeam.id])
  }, [])

  const is429 = logRows && logRows.some(log => log.status_code === 429)
  if (is429) {
    return (
      <Text className="py-2 text-red-500">
        status code 429:
        {' '}
        {teamPlanInfo?.name === 'Free' ? 'Please upgrade to the Growth plan to send more requests.' : 'Please recharge your quota to send more requests.'}
      </Text>
    )
  }
}
