import { Box, Button, Card, Flex, Inset, Select, Separator, Text, TextField } from '@radix-ui/themes'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { setCurrentProjectSettings, updateCurrentProjectSettings, useProjectStore } from '~/stores/project'
import { useTeamStore } from '~/stores/team'

const MODEL_OPTIONS = [
  { label: 'GPT-4o', value: 'gpt-4o' },
  { label: 'Gemini 1.5 Pro', value: 'gemini-1.5-pro' },
]

export function UpdateProjectSettings() {
  const projectStore = useProjectStore()
  const [baselimeInputValue, setBaselimeKeyInputValue] = useState('')
  const [modelSelectValue, setModelSelectValue] = useState('')
  const [showApiKey, setShowApiKey] = useState(false)
  const [saving, setSaving] = useState(false)
  const teamStore = useTeamStore()

  useEffect(() => {
    setBaselimeKeyInputValue(projectStore.currentProjectSettings.baselime_api_key || '')
    setModelSelectValue(projectStore.currentProjectSettings.model || '')
  }, [projectStore.currentProjectSettings])

  async function handleSave() {
    const baselimeValue = baselimeInputValue.trim()
    setSaving(true)
    try {
      const data = await updateCurrentProjectSettings(projectStore.currentProject.id, {
        baselime_api_key: baselimeValue || '',
        model: modelSelectValue || '',
      })
      setCurrentProjectSettings(data)
      toast.success('Project settings updated')
    }
    catch (error) {
      toast.error('Failed to update project settings')
    }
    finally {
      setSaving(false)
    }
  }

  return (
    <Card size="3">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Text size="5" weight="bold">Baselime API Key</Text>
          <Text size="2" className="text-[--accent-9]">
            Set the Baselime API key for your project.
          </Text>
        </Flex>

        <TextField.Root size="3" placeholder="" value={baselimeInputValue} onChange={e => setBaselimeKeyInputValue(e.target.value.trim())} type={showApiKey ? 'text' : 'password'}>
          <TextField.Slot side="right">
            <Box onClick={() => setShowApiKey(!showApiKey)} className="cursor-pointer">{showApiKey ? <EyeIcon /> : <EyeOffIcon />}</Box>
          </TextField.Slot>
        </TextField.Root>

        <Inset my="6">
          <Separator size="4" />
        </Inset>
        {teamStore.allTeamsPlan[teamStore.currentTeam.id]?.name === 'Growth' && (
          <>
            <Flex direction="column" gap="1">
              <Text size="5" weight="bold">Model</Text>
            </Flex>
            <Select.Root value={modelSelectValue} onValueChange={val => setModelSelectValue(val)}>
              <Select.Trigger />
              <Select.Content>
                {MODEL_OPTIONS.map((model) => {
                  return (
                    <Select.Item key={model.value} value={model.value}>{model.label}</Select.Item>
                  )
                })}
              </Select.Content>
            </Select.Root>
            <Inset my="6">
              <Separator size="4" />
            </Inset>
          </>
        )}
        <Flex justify="end" align="center">
          <Button onClick={handleSave} loading={saving}>Save</Button>
        </Flex>
      </Flex>
    </Card>
  )
}
