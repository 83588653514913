import { Flex, Spinner } from '@radix-ui/themes'
import { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { Helmet } from 'react-helmet-async'
import { useAsync } from 'react-use'
import { fetchCurrentProject, useProjectStore } from '~/stores/project'

export function Project() {
  const params = useParams()
  const projectStore = useProjectStore()
  const [projectLoaded, setProjectLoaded] = useState(false)

  useAsync(async () => {
    if (!params.projectName)
      return
    try {
      await fetchCurrentProject(params.projectName!)
    }
    catch (error) {
      console.error(error)
      toast.error('Failed to get project')
    }
    finally {
      setProjectLoaded(true)
    }
  }, [params.projectName])

  return (
    <>
      {projectStore.currentProject.name
        ? (
          <>
            <Helmet>
              <title>
                {projectStore.currentProject.name}
                {' '}
                - Project
              </title>
            </Helmet>
            {projectLoaded
              ? <Outlet />
              : (
                <Flex className="h-full" direction="column" align="center" justify="center" gap="3">
                  <Spinner size="3" />
                </Flex>
                )}
          </>
          )
        : (
          <Flex className="h-full" direction="column" align="center" justify="center" gap="3">
            <Spinner size="3" />
          </Flex>
          )}
    </>
  )
}
