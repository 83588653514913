import { Tooltip } from '@radix-ui/themes'
import { useEffect, useRef, useState } from 'react'

interface TruncatedTextProps {
  text: string
}

export function TruncatedText({ text }: TruncatedTextProps) {
  const textRef = useRef<HTMLDivElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)

  const checkOverflow = () => {
    if (textRef.current) {
      const isOverflowing = textRef.current.scrollWidth > textRef.current.clientWidth
      setIsTruncated(isOverflowing)
    }
  }

  useEffect(() => {
    const currentTextRef = textRef.current
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow()
    })
    if (currentTextRef)
      resizeObserver.observe(currentTextRef)

    return () => {
      if (currentTextRef)
        resizeObserver.unobserve(currentTextRef)
    }
  }, [text])

  return (
    <div className="truncate" ref={textRef}>
      {isTruncated
        ? (
          <Tooltip content={text}>
            <span>{text}</span>
          </Tooltip>
          )
        : text}
    </div>
  )
}
