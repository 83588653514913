import { fetchMe } from './user'
import { logto, signIn } from '~/libs/logto'

let isInitialized = false
export async function initStore() {
  if (isInitialized)
    return false
  isInitialized = true

  try {
    const isAuthenticated = await logto.isAuthenticated()
    if (!isAuthenticated) {
      signIn()
      return false
    }
  }
  catch (error) {
    signIn()
    return false
  }

  // fetch me must be called before fetchAllTeams
  await fetchMe()

  return true
}
