import { Link } from 'react-router-dom'
import { Box, Flex, Grid, Text } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { UpdateProjectAvatar } from '~/views/project/UpdateProjectAvatar.tsx'
import { UpdateProjectName } from '~/views/project/UpdateProjectName.tsx'
import { Content } from '~/views/layout/Content.tsx'
import { useProjectStore } from '~/stores/project.ts'
import { DeleteProject } from '~/views/project/DeleteProject'
import { UpdateProjectSettings } from '~/views/project/UpdateProjectSettings'

export default function TeamSetting() {
  const projectStore = useProjectStore()

  return (
    <Content title="Project Settings" row>
      <Helmet>
        <title>
          Settings /
          {' '}
          {projectStore.currentProject.name}
        </title>
      </Helmet>

      <Box width="15rem">
        <Grid gap="4">
          <Link to="#">
            <Text size="3" weight="medium">
              General
            </Text>
          </Link>
        </Grid>
      </Box>
      <Flex direction="column" gap="6" className="flex-1">
        <Flex direction="column" gap="6">
          <UpdateProjectName />
          <UpdateProjectAvatar />
          <UpdateProjectSettings />
          <DeleteProject />
        </Flex>
      </Flex>
    </Content>
  )
}
