import { Spinner } from '@radix-ui/themes'
import type { ColumnDef, ColumnResizeDirection, ColumnResizeMode } from '@tanstack/react-table'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import type { ProjectLog } from './useLog'
import { ProjectLogStatusCode } from './ProjectLogStatusCode'
import { cn } from '~/libs/utils'
import { TruncatedText } from '~/components/TruncatedText'
import { COUNTRY_REGION_MAP } from '~/constants'

interface ProjectLogTableProps {
  error: string | null
  data: ProjectLog[]
  loading: boolean
}

export function ProjectLogTable({ data, loading, error }: ProjectLogTableProps) {
  const [columnResizeMode] = useState<ColumnResizeMode>('onChange')
  const [columnResizeDirection] = useState<ColumnResizeDirection>('ltr')
  const columns = useMemo<ColumnDef<ProjectLog>[]>(
    () => [
      {
        accessorKey: 'ts',
        id: 'ts',
        header: 'Time',
        cell: (info) => {
          const value = info.getValue()
          if (!value)
            return '--'
          return format(value as string, 'yyyy-MM-dd HH:mm:ss')
        },
        size: 180,
      },
      {
        accessorKey: 'status_code',
        id: 'status_code',
        header: 'Status',
        size: 80,
      },
      {
        accessorKey: 'mesh_zone',
        id: 'mesh_zone',
        header: 'Mesh Node',
        size: 140,
        cell: ({ getValue }) => {
          const country = getValue() as string
          const region = COUNTRY_REGION_MAP[country]
          return region || country
        },
      },
      {
        accessorKey: 'uri',
        id: 'uri',
        header: 'URL',
        size: 180,
        cell: ({ getValue }) => <TruncatedText text={getValue() as string} />,
      },
      {
        accessorKey: 'user_agent',
        id: 'user_agent',
        header: 'Agent',
        cell: ({ getValue }) => <TruncatedText text={getValue() as string} />,
      },
    ],
    [],
  )
  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    columnResizeDirection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  if (error)
    return <div className="flex h-full items-center justify-center text-red-700 dark:text-red-500">{error}</div>
  if (loading) {
    return (
      <div className="flex h-full items-center justify-center p-4">
        <Spinner size="3" />
      </div>
    )
  }
  return (
    <div className="flex h-full flex-col">
      <ProjectLogStatusCode logRows={data} />
      <div className="relative h-0 flex-1 text-sm">
        <div className="flex h-full flex-col">
          <div className="shrink-0 grow-0">
            {table.getHeaderGroups().map(headerGroup => (
              <div key={headerGroup.id} className="flex backdrop-blur-sm" style={{ backgroundColor: 'var(--accent-a3)' }}>
                {headerGroup.headers.map(header => (
                  <div
                    key={header.id}
                    className={cn('relative p-2 h-[36px] font-bold', {
                      'flex-1 w-0': header.id === 'user_agent',
                      'flex-shrink-0 flex-grow-0': header.id !== 'user_agent',
                    })}
                    style={{ width: header.id !== 'user_agent' ? header.column.getSize() : 0 }}
                  >
                    {!header.isPlaceholder
                    && flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanFilter() && header.id !== 'user_agent' && (
                      <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={cn('absolute z-10 top-0 bottom-0 right-0 w-4 h-full text-center cursor-col-resize flex items-center justify-center select-none touch-none after:w-[1px] after:h-[50%] after:bg-slate-300 dark:after:bg-slate-600', table.options.columnResizeDirection, {
                          'after:bg-slate-400 dark:after:bg-slate-800': header.column.getIsResizing(),
                        })}
                        style={{
                          transform:
                            columnResizeMode === 'onEnd'
                            && header.column.getIsResizing()
                              ? `translateX(${
                                  (table.options.columnResizeDirection === 'rtl'
                                    ? -1
                                    : 1)
                                    * (table.getState().columnSizingInfo.deltaOffset ?? 0)
                                }px)`
                              : '',
                        }}
                      >
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="flex-1 overflow-y-auto">
            {table.getRowModel().rows.map((row) => {
              return (
                <div
                  data-index={row.index}
                  key={row.id}
                  className={cn('flex border-b', {
                    'text-red-500': row.original.status_code === 429,
                  })}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <div
                        key={cell.id}
                        style={{ width: cell.column.id !== 'user_agent' ? cell.column.getSize() : 0 }}
                        className={
                          cn('p-2', {
                            'flex-1 w-0': cell.column.id === 'user_agent',
                            'flex-shrink-0 flex-grow-0': cell.column.id !== 'user_agent',
                          })
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
