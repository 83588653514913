import { useContext, useEffect, useState } from 'react'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atelierLakesideDark, atelierLakesideLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { CopyIcon } from '@radix-ui/react-icons'
import { IconButton } from '@radix-ui/themes'
import copy from 'copy-to-clipboard'
import { toast } from 'sonner'
import yaml from 'react-syntax-highlighter/dist/esm/languages/hljs/yaml'
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash'
import { ThemeContext } from './ThemeProvider'
import createElement from '~/libs/codeblock'
import { cn } from '~/libs/utils'

SyntaxHighlighter.registerLanguage('yaml', yaml)
SyntaxHighlighter.registerLanguage('bash', bash)

interface CodeBlockProps {
  inline?: boolean
  code: string
  language?: string
}

export function CodeBlock({ code, language = 'yaml', inline = false }: CodeBlockProps) {
  const { theme } = useContext(ThemeContext)
  const [isDark, setIsDark] = useState(false)

  function copyText(text: string) {
    const success = copy(text.replace(/\*\{|\}\*/g, ''))
    if (success)
      toast.success('Copied to clipboard')
    else
      toast.error('Failed to copy to clipboard')
  }

  useEffect(() => {
    if (theme === 'system') {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
      setIsDark(prefersDark)
    }
    else if (theme === 'dark') {
      setIsDark(true)
    }
    else {
      setIsDark(false)
    }
  }, [theme])

  function customRenderer({ rows, stylesheet, useInlineStyles }: any) {
    return rows.map((node: any, i: number) =>
      createElement({
        node,
        stylesheet,
        useInlineStyles,
        key: `code-segment${i}`,
      }),
    )
  }

  return (
    <div className={cn('code-block relative w-full', {
      'flex items-center w-auto max-w-full static': inline,
    })}
    >
      <div className={cn('text-base flex-1')}>
        <SyntaxHighlighter
          wrapLongLines
          language={language}
          renderer={customRenderer}
          style={isDark ? atelierLakesideDark : atelierLakesideLight}
        >
          {code}
        </SyntaxHighlighter>
      </div>

      <IconButton
        className={cn({
          'flex-shrink-0 flex-grow-0': inline,
          'absolute right-[0.5em] top-[0.5em]': !inline,
        })}
        size="1"
        aria-label="Copy value"
        color="gray"
        variant="ghost"
        onClick={() => {
          copyText(code)
        }}
      >
        <CopyIcon />
      </IconButton>
    </div>
  )
}
