import { useCallback, useState } from 'react'
import { useMount } from 'react-use'
import greptime from '~/libs/greptime'

export interface ProjectLog {
  ts: string
  app_id: string
  mesh_zone: string
  remote_ip: string
  remote_port: number
  uri: string
  referer: string
  user_agent: string
  status_code: number
  body_bytes: number
}

interface UseLogProps {
  appId: string
  initialLimit?: string
}

function genRows<T extends Record<string, any>>(schema: { name: keyof T }[], rows: unknown[][]): T[] {
  return rows.map((row) => {
    const transformedRow: Partial<T> = {}
    schema.forEach((field, index) => {
      if (index < row.length)
        transformedRow[field.name] = row[index] as T[keyof T]
    })
    return transformedRow as T
  })
}

export function useLog({ appId, initialLimit = '50' }: UseLogProps) {
  const [logs, setLogs] = useState<ProjectLog[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [hasMore, setHasMore] = useState(true)
  const [pageHistory, setPageHistory] = useState<string[]>([])
  const [currentPageIndex, setCurrentPageIndex] = useState(-1)
  const [limit, setLimit] = useState<string>(initialLimit)

  const fetchPage = useCallback(async (lastTs?: string, newLimit?: string) => {
    setIsLoading(true)
    setError(null)
    try {
      const currentLimit = newLimit || limit
      let query = `SELECT * FROM access_log WHERE app_id = '${appId}' `
      if (lastTs)
        query += `AND ts < ${lastTs} `
      else
        query += `AND ts < ${+new Date()} `
      query += `ORDER BY ts DESC LIMIT ${currentLimit}`

      const res = await greptime.sql.query(query)
      const schema = res?.schema || []
      const rows = res?.rows || []
      const newLogs = genRows<ProjectLog>(schema, rows)
      setLogs(newLogs)
      setHasMore(newLogs.length === Number(currentLimit))

      if (!lastTs) {
        setPageHistory([query])
        setCurrentPageIndex(0)
      }
      else {
        setPageHistory(prev => [...prev.slice(0, currentPageIndex + 1), query])
        setCurrentPageIndex(prev => prev + 1)
      }
    }
    catch (err) {
      setError('Failed to fetch logs')
    }
    finally {
      setIsLoading(false)
    }
  }, [appId, currentPageIndex, limit])

  const nextPage = useCallback(() => {
    if (logs.length > 0) {
      const lastTs = logs[logs.length - 1].ts
      fetchPage(lastTs)
    }
  }, [logs, fetchPage])

  const prevPage = useCallback(() => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(prev => prev - 1)
      const prevQuery = pageHistory[currentPageIndex - 1]
      setIsLoading(true)
      setError(null)
      greptime.sql.query(prevQuery)
        .then((res) => {
          const schema = res?.schema || []
          const rows = res?.rows || []
          const newLogs = genRows<ProjectLog>(schema, rows)
          setLogs(newLogs)
          setHasMore(true)
        })
        .catch(() => setError('Failed to fetch logs'))
        .finally(() => setIsLoading(false))
    }
  }, [currentPageIndex, pageHistory])

  const changeLimit = useCallback((newLimit: string) => {
    setLimit(newLimit)
    setPageHistory([])
    setCurrentPageIndex(-1)
    fetchPage(undefined, newLimit)
  }, [fetchPage])

  useMount(() => {
    fetchPage()
  })

  return {
    logs,
    isLoading,
    error,
    hasMore,
    hasPrevious: currentPageIndex > 0,
    limit,
    limits: ['50', '100', '200'],
    fetchPage,
    nextPage,
    prevPage,
    changeLimit,
  }
}
