import { useAsync, useMount } from 'react-use'
import { Outlet, matchPath } from 'react-router-dom'
import { useState } from 'react'
import { Flex, ScrollArea, Spinner, Text } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { initStore } from '~/stores'
import { useUserStore } from '~/stores/user'
import { Sidebar } from '~/views/layout/Sidebar.tsx'
import '~/views/layout/layout.css'
import { fetchAllTeams, setCurrentTeam, teamStore } from '~/stores/team'

export function App() {
  const [isReady, setIsReady] = useState(false)
  const [error, setError] = useState('')
  const userStore = useUserStore()
  const [teamLoaded, setTeamLoaded] = useState(false)

  useMount(async () => {
    try {
      const isReady = await initStore()
      if (isReady)
        setIsReady(true)
    }
    catch (error) {
      setError('Failed to initialize app')
    }
  })

  useAsync(async () => {
    if (!isReady)
      return
    await fetchAllTeams()
    const match = matchPath('/:teamSlug/*', location.pathname)
    let teamSlug = ''
    if (match !== null)
      teamSlug = match.params.teamSlug!

    const allTeams = teamStore.allTeams

    if (teamSlug === '') {
      const me = userStore.me
      const defaultTeam = allTeams.find(team => team.id === me.defaultTeamId)
      if (defaultTeam)
        teamSlug = defaultTeam.slug
      else
        teamSlug = allTeams[0].slug
    }

    setCurrentTeam(teamSlug)
    setTeamLoaded(true)
  }, [isReady])

  if (error !== '')
    return <div>{error}</div>

  return (isReady
    ? (
      <Flex className="relative h-dvh min-w-[1024px] overflow-hidden bg-[--accent-3] p-4 [--sidebar-width:17rem] dark:bg-[--accent-2]">
        <Helmet titleTemplate="%s / vivgrid">
          <title>Dashboard</title>
        </Helmet>

        <Sidebar approved={Boolean(userStore.me.id)} />

        <ScrollArea scrollbars="vertical" className="relative flex h-full flex-1 flex-col overflow-y-auto bg-[--accent-surface]">
          {teamLoaded
            ? <Outlet />
            : (
              <Flex className="h-full" direction="column" align="center" justify="center" gap="3">
                <Spinner size="3" />
              </Flex>
              )}
        </ScrollArea>

        <div className="pointer-events-none absolute inset-0 select-none">
          <div className="GridLine GridLine--Y absolute left-[calc(var(--sidebar-width)/3)] top-4 w-[calc(100vw-var(--sidebar-width)/3)]" />
          <div className="GridLine GridLine--Y absolute bottom-4 left-[calc(var(--sidebar-width)/3)] w-[calc(100vw-var(--sidebar-width)/3)]" />
          <div className="GridLine GridLine--X absolute left-[calc(var(--sidebar-width)+1rem)] top-0 h-screen" />
          <div className="GridLine GridLine--X absolute right-4 top-0 h-screen" />
        </div>
      </Flex>
      )
    : (
      <Flex align="center" justify="center" className="h-dvh">
        <Flex direction="column" align="center" justify="center" gap="3">
          <Text>initiating vivgrid...</Text>
          <Spinner size="3" />
        </Flex>
      </Flex>
      )
  )
}
