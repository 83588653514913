import Greptime from 'greptime'

const config = atob(import.meta.env.VITE_GREPTIME || '').split('$$')

export default Greptime({
  host: config[0],
  dbname: config[1],
  username: config[2],
  password: config[3],
})
